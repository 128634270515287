export const environment = {
    production: false,
    environmentName: 'staging',
    storagePrefix: 'fsp_web_app',
    title: 'Fire Survey Pro',
    apiUrl: 'https://staging.api.vulcan.hickwood.uk/api/v1',
    webPath: 'https://staging.api.vulcan.hickwood.uk',
    localPath: 'https://battyonline.co.uk',
    sentryDSN: 'https://1942885698bd421ba0b878f01d9fa46b@o1040403.ingest.sentry.io/4505156119232512',
};

import 'zone.js/dist/zone-error';
